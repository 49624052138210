var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-textarea',{attrs:{"filled":"","label":"Sender ID","rows":"2","rules":[
      (v) => !!v || 'Sender ID is required',
      (v) => /^[0-9a-fA-F]+$/.test(v) || 'Hexadecimal format is expected',
    ],"hint":"Hexadecimal format","spellcheck":"false"},on:{"input":function($event){return _vm.$emit('input', _vm.oscore)}},model:{value:(_vm.oscore.sid),callback:function ($$v) {_vm.$set(_vm.oscore, "sid", $$v)},expression:"oscore.sid"}}),_c('v-textarea',{attrs:{"filled":"","label":"Master Secret","rows":"2","rules":[
      (v) => !!v || 'Master Secret is required',
      (v) => /^[0-9a-fA-F]+$/.test(v) || 'Hexadecimal format is expected',
    ],"hint":"Hexadecimal format","spellcheck":"false"},on:{"input":function($event){return _vm.$emit('input', _vm.oscore)}},model:{value:(_vm.oscore.msec),callback:function ($$v) {_vm.$set(_vm.oscore, "msec", $$v)},expression:"oscore.msec"}}),_c('v-textarea',{attrs:{"filled":"","label":"Recipient ID","rows":"2","rules":[
      (v) => !!v || 'Recipient ID is required',
      (v) => /^[0-9a-fA-F]+$/.test(v) || 'Hexadecimal format is expected',
    ],"hint":"Hexadecimal format","spellcheck":"false"},on:{"input":function($event){return _vm.$emit('input', _vm.oscore)}},model:{value:(_vm.oscore.rid),callback:function ($$v) {_vm.$set(_vm.oscore, "rid", $$v)},expression:"oscore.rid"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }