var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"hide-overlay":"","fullscreen":"","transition":"dialog-bottom-transition"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline grey lighten-2"},[_c('span',[_vm._v(_vm._s(_vm.editMode ? "Edit Composite Object" : "Add Composite Object"))])]),_c('v-card-text',[_c('p',{staticClass:"subtitle-1"},[_vm._v(" A Composite Object has a name and a list of path to LWM2M node (Object, Object Instance, Resource, Resource Instance). ")]),_c('p',{staticClass:"subtitle-1"},[_vm._v(" Root path is not yet supported. "),_c('br'),_vm._v(" Overlapped path will not work. (e.g. /3/0 with /3/0/1 is not valid) ")]),_c('div',[_vm._v(" Note if you create Composite Object composed of too much resources, you could face some UI performance issue. "),_c('a',{attrs:{"href":"https://github.com/eclipse/leshan/issues/1016"}},[_vm._v("This is a UI demo limitation")]),_vm._v(", if you have skills in vue.js your help will be welcome. ")]),_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"rules":[
            (v) => !!v || 'name is required',
            (v) =>
              _vm.editMode ||
              !v ||
              !_vm.alreadyUsedName.includes(v) ||
              'name already used',
          ],"label":"Name","required":"","autofocus":!_vm.editMode,"disabled":_vm.editMode},model:{value:(_vm.compositeObject.name),callback:function ($$v) {_vm.$set(_vm.compositeObject, "name", $$v)},expression:"compositeObject.name"}}),_c('paths-input',{model:{value:(_vm.compositeObject.paths),callback:function ($$v) {_vm.$set(_vm.compositeObject, "paths", $$v)},expression:"compositeObject.paths"}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","disabled":!_vm.valid},on:{"click":function($event){return _vm.$emit(_vm.editMode ? 'edit' : 'new', _vm.compositeObject)}}},[_vm._v(" "+_vm._s(_vm.editMode ? "Save" : "Add")+" ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.show = false}}},[_vm._v(" Cancel ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }