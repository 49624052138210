var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grey lighten-4 pa-4 mb-1"},[_c('span',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"small":""},on:{"click":_vm.addPath}},[_vm._v(" "+_vm._s(_vm.addButtonText)+" ")])],1),_c('v-btn',{attrs:{"small":""},on:{"click":_vm.removeAllPath}},[_vm._v(" Remove All ")]),_vm._l((_vm.value),function(path,index){return _c('div',{key:index},[_c('v-text-field',{attrs:{"value":path,"rules":[
        (v) => !!v || 'path can not be empty required',
        (v) =>
          !v ||
          /^((\/([1-9][0-9]{0,4}|[0])){0,4})$|^\/$/.test(v) ||
          'invalid path',
      ],"placeholder":"a path to a LWM2M node (e.g. /3/0/1 or /3/0/11/0)","required":"","dense":"","append-outer-icon":_vm.$icons.mdiDelete},on:{"input":function($event){return _vm.updatePath(index, $event)},"click:append-outer":function($event){return _vm.removePath(index)}}})],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }