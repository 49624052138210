<template>
  <div class="px-2 py-2">
    <v-data-table
      :headers="headers"
      :items="files"
      class="elevation-1"
      density="compact"
      item-key="name"
    >
      <!-- File Size Custom Col -->
      <template v-slot:item.fileSize="{ item }">
        <span>{{ formatFileSize(item.fileSize) }}</span>
      </template>
      <!-- Last Updated Custom Col -->
      <template v-slot:item.lastUpdate="{ item }">
        <span>{{ formatDateCol(item.lastUpdate) }}</span>
      </template>
      <!-- Last Downloaded Custom Col -->
      <template v-slot:item.lastDownload="{ item }">
        <span>{{ formatDateCol(item.lastDownload) }}</span>
      </template>
      <!-- Actions Custom Col -->
      <template v-slot:item.actions="{ item }">
        <v-row>
          <div class="pa-0 d-flex justify-content-center mx-auto">
            <v-btn
              icon
              color="error"
              @click="handleDelete(item.actions.delete)"
            >
              <v-icon>{{ icons.deleteIcon }}</v-icon>
            </v-btn>

            <v-btn icon @click="handleDownload(item.actions.download)">
              <v-icon>{{ icons.downloadIcon }}</v-icon>
            </v-btn>
            <v-btn icon @click="handleClipboard(item.actions.copy)">
              <v-icon>{{ icons.copyIcon }}</v-icon>
            </v-btn>
          </div>
        </v-row>
      </template>
      
    </v-data-table>
    <v-speed-dial
      v-model="fab"
      :bottom=true
      :right=true
      direction="top"
      :open-on-hover=true
      transition="slide-y-reverse-transition"
      style="max-width: fit-content; position: absolute"
    >
      <template v-slot:activator>
        <v-btn v-model="fab" color="blue darken-2" dark fab>
          <v-icon style="transform: rotate(0deg)" v-if="fab">
            {{ icons.speedDialIcon }}
          </v-icon>
          <v-icon style="transform: rotate(-90deg)" v-else>
            {{ icons.speedDialIcon }}
          </v-icon>
        </v-btn>
      </template>
      <v-btn fab dark small color="green" @click="uploadForm.visible = true">
        <v-icon>{{ icons.uploadIcon }}</v-icon>
      </v-btn>
    </v-speed-dial>
    <v-row justify="center">
      <v-dialog v-model="uploadForm.visible" persistent max-width="600px">
        <v-card max-width="600px">
          <v-card-title>
            <span class="text-h5">Firmware Upload</span>
          </v-card-title>
          <v-card-text>
            <v-form
              v-model="uploadForm.valid"
              @submit.prevent="uploadFirmwareSubmit"
              ref="uploadForm"
            >
              <v-container>
                <v-row>
                  <!-- Form Fields -->
                  <v-col cols="12">
                    <v-text-field
                      label="Firmware"
                      required
                      v-model="uploadForm.fields.firmware.value"
                      :rules="uploadForm.fields.firmware.rules"
                      hint="Firmware name e.g. lwm2m-pulse"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="6">
                    <v-text-field
                      label="Hardware Version"
                      v-model="uploadForm.fields.hardwareVersion.value"
                      :rules="uploadForm.fields.hardwareVersion.rules"
                      hint="Semver value e.g. 1.0.1"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" xl="6">
                    <v-text-field
                      label="Software Version"
                      v-model="uploadForm.fields.softwareVersion.value"
                      :rules="uploadForm.fields.softwareVersion.rules"
                      hint="Semver value e.g. 1.0.1"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-file-input
                      label="Firmware File"
                      v-model="uploadForm.fields.file.value"
                      :rules="uploadForm.fields.file.rules"
                      required
                    ></v-file-input>
                  </v-col>

                  <!-- Form Buttons -->
                  <v-col cols="12" xl="6" class="pt-1">
                    <v-btn
                      block
                      color="error"
                      text
                      @click="uploadForm.visible = false"
                      >Cancel</v-btn
                    >
                  </v-col>
                  <v-col cols="12" xl="6" class="pt-1">
                    <v-btn
                      :loading="uploadForm.loading"
                      type="submit"
                      block
                      color="blue darken-1"
                      text
                      >Upload</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>
<script>
export default {
  methods: {
    /**
     * 
     * @param {number} fileSize 
     */
    formatFileSize: (fileSize) => {
      if (typeof fileSize !== "number") return ' - ';
      return `${fileSize / 1000} KB`;
    },

    /**
     * 
     * @param {Date} date 
     */
    formatDateCol: (date) => {
      if (date === null || date === undefined) return ' - '
      const SHORT_MONTH_LOOKUP = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      // date
      const year = date.getFullYear();
      const month = SHORT_MONTH_LOOKUP[date.getMonth()]
      const day = String(date.getDate());
      // time
      const hour = String(date.getHours()).padStart(2, '0');
      const minute = String(date.getMinutes()).padStart(2, '0');
      const second = String(date.getSeconds()).padStart(2, '0');
      const o = `${month} ${day}, ${year}, ${hour}:${minute}:${second}`
      console.log(o)
      return o
    },

    async loadTableFirmwareData() {
      this.axios
        .get("api/files")
        .then((response) => {
          const { data } = response
          if (!Array.isArray(data)) return;
          const parsed = data.map((d) => {
            let lastDownload = d.lastDownload;
            if (lastDownload !== null){
              lastDownload = new Date(Math.round(lastDownload * 1000))
            }
            let lastUpdate = d.lastUpdate;
            if (lastUpdate !== null){
              lastUpdate = new Date(Math.round(lastUpdate * 1000))
            }
            const coapPort = window.location.hostname.includes('dev') || window.location.hostname === 'localhost' ? 5684 : 5683;
            const actions = {
              copy: `coaps://${window.location.hostname}:${coapPort}/files/${d.fileName}`,
              delete: `api/files/${d.fileName}`,
              download: `api/files/${d.fileName}`
            }
            return {...d, lastDownload, lastUpdate, actions }
          })
          this.$data.files = parsed;
        });
    },

    /**
     * 
     * @param {string} url 
     */
    async handleDelete(url) {
      const res = await this.$dialog.confirm({
        text: "Do you really want to delete this firmware ?",
        title: "Delete Firmware",
      });
      if (res !== true) return;
      // send firmware deletion request
      this.axios.delete(url)
      .then(() => {
        return this.loadTableFirmwareData()
      })
    },

    /**
     * 
     * @param {string} url 
     */
    async handleDownload(url) {
      const label = url.split('/').slice(-1)[0]
      this.axios.get(url, { responseType: 'blob' })
      .then(response => {
        const blob = new Blob([response.data], { type: 'application/octet-stream' })
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = label
        link.click()
        URL.revokeObjectURL(link.href)
      }).catch(console.error)
    },

    /**
     * 
     * @param {string} url 
     */
    handleClipboard(url) {
      navigator.clipboard.writeText(url);
    },

    async uploadFirmwareSubmit() {
      // validate form
      this.$refs.uploadForm.validate();
      if (this.$data.uploadForm.valid === false) return;
      this.uploadForm.loading = true;

      // build form
      const formData = new FormData();
      formData.append("file", this.$data.uploadForm.fields.file.value);
      formData.append("firmware", this.$data.uploadForm.fields.firmware.value);
      formData.append(
        "hardwareVersion",
        this.$data.uploadForm.fields.hardwareVersion.value
      );
      formData.append(
        "softwareVersion",
        this.$data.uploadForm.fields.softwareVersion.value
      );

      // submit form
      this.axios
        .post("api/files/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(() => {
          return this.loadTableFirmwareData()
        })
        .then(() => {
          this.uploadForm.visible = false;
        })
        .finally(() => {
          this.uploadForm.loading = false;
        });
    },
  },

  data() {
    return {
      uploadForm: {
        visible: false,
        loading: false,
        valid: false,
        fields: {
          firmware: {
            value: "",
            rules: [(v) => !!v || "Firmware is required"],
          },
          hardwareVersion: {
            value: "",
            rules: [(v) => !!v || "Hardware version is required"],
          },
          softwareVersion: {
            value: "",
            rules: [(v) => !!v || "Software version is required"],
          },
          file: {
            value: "",
            rules: [(v) => !!v || "Firmware File is required"],
          },
        },
      },
      icons: {
        deleteIcon: this.$icons.mdiDelete,
        copyIcon: this.$icons.mdiFileLink,
        downloadIcon: this.$icons.mdiDownload,
        speedDialIcon: this.$icons.mdiDotsVertical,
        uploadIcon: this.$icons.mdiUpload,
      },
      itemsPerPage: 15,
      files: [],
      fab: false,
      headers: [
        {
          text: "File",
          align: "start",
          value: "fileName",
        },
        { text: "Size", value: "fileSize", align: "start" },
        { text: "Downloads", value: "downloadCount", align: "start" },
        { text: "Updated", value: "lastUpdate", align: "start" },
        { text: "Last Downloaded", value: "lastDownload", align: "start" },
        { text: "Version", value: "variant", align: "start" },
        { text: "Actions", value: "actions", align: "center" },
      ],
    };
  },

  beforeMount() {
    this.loadTableFirmwareData()
  },
};
</script>
